.root {
  height: 100%;
  max-height: 100vh;
  overflow: none;
  width: 240px;
  max-width: 240px; 
  min-width: 240px; 
  display: flex;
  row-gap: 0;
  flex-direction: column;
  padding: 0;
}

.root > * {
  padding-left: 20px;
  padding-right: 10px;
}

.root > *:last-child {
  padding-bottom: 10px;
}

@media (max-width: 1600px) {
  .root {
    max-width: 210px;
  }
}

@media (max-width: 1200px) {
  .root {
    max-width: 180px;
  }
}

.backMenu {
  margin-top: -6px;
}

.group + .group {
  margin-top: 26px;
}

.menuItem.menuItem {
  color: var(--color-text-secondary);
}

.menuItem.archived.open {
  background-color: var(--color-subtle-primary-alt);
}

.menuItem.archived:hover {
  background-color: var(--color-subtle-primary-alt-hover);
}

.menuItem.archived:global(.active),
.menuItem.archived:active {
  background-color: var(--color-subtle-primary-alt-active);
}

.fill {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}

.labels {
  flex: 0 0 auto;
}


.onHoverTarget {
  position: absolute;
  right: 8px;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.2s ease;
}

.onHover:hover .onHoverTarget {
  opacity: 1;
  transform: translateX(0);
}

.divider {
  margin: 8px 0;
  background-color: var(--color-border);
}

.primaryAction.primaryAction {
  border-radius: 100px;
  padding-left: 8%;
  padding-right: 8%;
  width: 50%;
}

.meetingButton,
.searchButton,
.newButton {
  padding-left: 6%;
  padding-right: 6%;
  text-align: center;
}

.newButton {
  border: none;
}

.newButton .icon {
  color: var(--color-text-inverse);
}

.searchButton {
  background-color: var(--color-subtle-active);
}

.meetingButton {
  border-radius: 100px;
  text-align: center;
  width: 100%;
  background-color: var(--color-subtle-danger);
}

.meetingButton .text {
  color: var(--color-text);
}

.clickable:hover {
  background-color: var(--color-subtle-hover);
  border-radius: var(--border-radius-small);
}

.clickable:not(:hover) .onHover {
  opacity: 0;
}

.clickable:hover > * {
  color: var(--color-text);
}

/*
* Collapsed State
*/

.root.collapsed {
  width: 60px;
  min-width: 60px;
}

.root.collapsed > * {
  padding-left: 12px;
}

.root.collapsed .menuItem {
  justify-content: center;
}

.root.collapsed .primaryAction {
  width: 35px;
}
.root.collapsed .primaryAction > * {
  justify-content: center;
}

.root.collapsed .text {
  display: none;
}


.hoverContainer {
  position: relative;
}

.overlay {
  position: absolute;
  top: -5px;
  left: 0;
  bottom: 5px;
  transform: translateX(0);
  background: rgba(var(--color-rgb-background), 0.8);
  backdrop-filter: blur(5px);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-elevation-high);
  opacity: 1;
  z-index: 1;
}

.overlay:not(.hovering) {
  transform: translateX(calc(-100% + 60px));
  opacity: 0;
}

.overlay .root {
  max-width: 240px !important;
  width: 240px;
  padding-right: 20px;
}