.side {
  display: block;
  min-width: 500px;
  width: 43%;
  max-width: 1200px;
  border-left: var(--border);
}

.settings {
  min-width: 400px;
  width: 20vw;
}

.control.control {
  background: var(--color-background-tertiary);
}

.flow {
  width: 100%;
  height: calc(max(50vh, 400px));
  border-radius: var(--border-radius-large);
  overflow: hidden;
  margin-bottom: 200px;
}