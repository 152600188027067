.modal {
  position: fixed;
  top: 40px;
  left: 50%;
  bottom: 0;
  overflow: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transform: translate(-50%, 0);
  max-width: min(80vw, 1100px);
  width: 100%;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
}


.nav {
  padding: 25px 20px;
  border-right: var(--border-secondary);
  min-height: 100%;
  display: block;
  width: 260px;
}

.navMenu {
  flex-grow: 1;
  flex-basis: 100%;
  /* row-gap: 15px; */
}

.logo {
  width: 100%;
}

.main.main {
  padding: 10px;
  background: rgba(var(--color-rgb-background), 0.9);
}